import React, { useState } from "react"
import styled from "styled-components"
// import { schedulePageData } from "../../services/apiRequests.js"

//GATSBY PAGE SEO + LAYOUT
import SEO from "../../components/seo.js"
import Layout from "../../components/layout.js"
// import CalendarColumn from "../../components/molecules/schedule_calendarColumn.js"
// import DateContent from "../../components/molecules/schedule_dateContent.js"

// PAGE COMPONENTS AND TEMPLATES.
import { InPageNav } from "../../components/navbar.js"
import { McatPricingV2 } from "../../components/sections/pricingSection.js"

// import { CtaPrimary } from "../../components/elements/buttons.js"
// import { Section } from "../../components/sections/sections.js"
// import { HeroTitleH1 } from "../../components/typography.js"
// import SimpleTabs from "../../components/molecules/tabs.js"
// const Hero = styled(Section)`
//   height: 60vh;
// `
// const Wrapper = styled.div`
//   width: 95%;
//   height: 100%;
//   height: auto;
//   display: flex;
//   flex-flow: row nowrap;
//   border-radius: 10px;
//   align-items: stretch;
//   margin: 0 auto;
//   background-color: rgb(223, 234, 245, 0.6);
//   backdrop-filter: blur(50px);
// `
// const AdjSection = styled(Section)``

// const card1 = {
//   packageName: "Part-Time",
//   price: "1500",
//   duration: "2 months",
//   description: "",
//   onClick: "Hello",
//   offered: [
//     "4+ Hour Days",
//     "505+ Score Guarantee",
//     "Be Fully Prepared In 4-6 Months",
//     "90+ Hours Recorded Video Content",
//     "Master-Focussed Approach",
//     "7000+ Content Questions",
//   ],
//   notOffered: [],
//   isRecommended: false,
// }
const CASPerData = {
  title: "CASPer",
  description: "For a one month course.",
  price: {
    full: "1500",
    installments: "750",
  },
}

const TabWrap = styled.div`
  display: flex;
  border-bottom: 1px solid rgb(0, 0, 0, 0.01);
  width: 100%;
  justify-content: space-around;
  position: sticky;
  top: 50px;
  min-height: 3em;
  background-color: ${props =>
    !!props.theme && props.theme === "dark" ? "transparent" : "transparent"};
  color: ${props =>
    !!props.theme && props.theme === "dark" ? "white" : "black"};
  // background-color: #fff;
  backdrop-filter: saturate(180%) blur(10px);
  z-index: 998;
`
const Tab = styled.div`
  height: 100%;
  box-sizing: border-box;
  color: ${props => (props.active ? "#f50057" : "black")};
  cursor: pointer;
`
//PreCARS => CARS => Physics => Chemistry => Orgo Biochem. => Bio => Psych Soc.
const CasperPricing = ({ location }) => {
  // const [updateCurr, setUpdate] = useState(0)
  const [currTab, setTab] = useState(0)
  const RedirectToPage = dest => {
    // navigate(dest)
  }
  return (
    <Layout navColor="transparent">
      <SEO title="99point9 CASPer Pricing" />
      <InPageNav
        pageTitle="CASPer Pricing"
        currentPath={location.pathname}
        navItems={[
          { link: "/casper", label: "Overview" },
          { link: "/casper/how_it_works", label: "How It Works" },
          { link: "/casper/schedule", label: "Class Schedule" },
          { link: "/casper/pricing", label: "Pricing" },
        ]}
        clickCallback={RedirectToPage}
      />
      <McatPricingV2
        backgroundColor={"rgb(247,247,247)"}
        data={CASPerData}
        dest="/casper/schedule/"
      />
      <TabWrap>
        <Tab onClick={() => setTab(0)} active={currTab === 0}>
          <h3>Comparison</h3>
        </Tab>
        <Tab onClick={() => setTab(1)} active={currTab === 1}>
          <h3>FAQs</h3>
        </Tab>
      </TabWrap>
      <h4 style={{ margin: "116px auto", textAlign: "center" }}>
        We're currently updating our site: These sections are coming soon.{" "}
      </h4>
    </Layout>
  )
}
export default CasperPricing
